.unit-navbar-v1 {
	background-color: var(--unit-navbar-bg-color);
	position: -webkit-sticky;
	position: sticky;
	z-index: 14;
	top: var(--page-margin-top);


	.fl-menu-itm {
		padding: 20px 10px;
		color: var(--unit-navbar-font-color-item);
		display: inline-block;
		text-transform: uppercase;
		font-size: var(--unit-navbar-font-size-item);

		&:hover {
			color: var(--unit-navbar-font-color-item-hover);
			background-color: var(--unit-navbar-bg-color-item-hover);
			text-decoration: none;
		}

		@media (max-width:1200px) {
			font-size: var(--font-size-md);
		}

	}

	@media (max-width:1199px) {
		top: 0;
	}

	@media (max-width:992px) {
		display: none;
	}
}