.footer-v1 {

	background-color: var(--color-secondary);

	.container {
		@media all and (max-width: 767px) {
			max-width: 100%;
		}
	}

	.pdf-icon {
		display: flex;
		align-items: center;

		&:before {
			display: block;
			content: " ";
			width: 16px;
			height: 16px;
			background: url(RESOURCE/img/pdf-icon.svg);
			margin: 0 3px 7px 0;
		}
	}


	.main-footer {

		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		padding-left: 50px;
		padding-right: 50px;
		padding-top: 120px;
		padding-bottom: 120px;

		@media all and (max-width: 992px) {
			padding: 40px 10px;
		}


		h4 {
			font-size: 26px;
			color: #fff;
			letter-spacing: 1px;
			font-weight: 400;
			margin: 0 0 15px 0;
		}

		p {
			font-size: 20px;
			color: #fff;
			margin-bottom: 15px;

			a {
				color: rgba(255, 255, 255, 0.7);
				pointer-events: unset !important;

				&:hover {
					color: #fff;
				}
			}
		}

		.phone {
			position: relative;
			display: flex;
			align-items: center;

			&:before {
				display: block;
				content: " ";
				width: 16px;
				height: 16px;
				background: url(RESOURCE/img/phone-icon.svg);
				margin: 0 3px 7px 0;
			}

		}

		.email {
			position: relative;
			display: flex;
			align-items: center;

			&:before {
				display: block;
				content: " ";
				width: 16px;
				height: 16px;
				background: url(RESOURCE/img/email-icon.svg);
				margin: 0 3px 7px 0;
			}

		}

		.headline {
			font-size: 16px;
			color: var(--footer-font-color);
			font-weight: bold;
			text-transform: uppercase;
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: 0;
			padding-bottom: 20px;
		}

		.openings {
			font-style: italic;

			div {
				padding-top: 10px;
			}
		}

		a {

			color: rgba(255, 255, 255, 0.7);
			font-size: 20px;
			text-decoration: none !important;

			&:hover {
				color: #fff;
			}
		}

		.social-icons {

			li {

				a {
					display: flex;
					width: 36px;
					height: 36px;
					align-items: center;
					justify-content: center;
					color: #fff !important;
				}



				.fa {
					font-size: 20px;
					color: #fff;

				}
			}

			.youtube {
				a {
					background: #CD201F;
				}
			}

			.facebook {
				a {
					background: #48629D;
				}
			}

			.envelope {
				a {
					background: #A0A0A0;
				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);
	}

}