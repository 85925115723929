.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);
	background-image: url(RESOURCE/img/search-bg.jpg);
	background-position: top;
	background-size: cover;
	background-attachment: fixed;

	@media (max-width: 1199px) {
		/*margin-top: 94px;*/
		margin-top: 0px;
	}

	@media (max-width: 992px) {
		/*margin-top: 94px;*/
		margin-top: 0px;
	}

	@media (max-width: 767px) {
		/*margin-top: 76px;*/
		margin-top: 0px;
	}
}