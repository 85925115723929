.unit-title-v1 {
	h1 {
		color: #fff;
		font-weight: normal;
	}

	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}
}